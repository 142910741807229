<template>
  <div id="app">
    <NewUser />
    <Header class="header" />
    <b-container fluid>
      <router-view class="bodyPadding" />
    </b-container>

  </div>
</template>

<script>
import Header from "./layouts/Header.vue";
import NewUser from "./components/NewUser.vue";
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "App",
  components: { Header, NewUser },
  methods: {
    ...mapMutations(['SET_USER_STATUS']),

  },
  data() {
    return {
      AppVer: "0.1.169",
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },
  created() {
    console.log(this.AppVer)
    if ( this.userIsAuthorized ) {
      this.$store.dispatch('checkUserStatus');
    }
  },
  computed: {
    ...mapGetters(['getUserStatus','userIsAuthorized']),
  },
  watch: {
    getUserStatus: {
      handler: function (val) {
        // console.log(val)
        // if (val.trialStarted != true && val.subscriptionId < 1) {
        if (val.trialStarted != true ) {
          this.$bvModal.show('NewUser')
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}

.success {
  color: green;
}

.sidebar {
  height: calc(100vh - 9rem);
  overflow: auto;
}

.sidebar-items:hover {
  background-color: #f8f9fa;
  text-decoration: none;
  color: #495057;
  cursor: pointer;
}

.header {
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
<style>
@media (min-width: 320px) and (max-width: 578px) {
  .table {
    font-size: 12px;
  }
}

.tab-res .table-responsive {
  overflow-x: unset;
}
</style>
